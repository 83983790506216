import React from "react";
import StepOne from "./StepOne";
import { GridCol, GridContainer, GridRow } from "layout/components/page-styles";
import Authentique from "./Authentique";
import GeneralData from "./GeneralData";

export interface GeneralProps {
  data: any;
}

const General: React.FC<GeneralProps> = ({ data }) => {
  return (
    <GridContainer style={{ marginBottom: "16px" }}>
      <GridRow>
        <GridCol
          xs={12}
          md={12}
          lg={12}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <GeneralData resultData={data} />

          {data.user.type === "COMPANY" && (
            <StepOne
              data={data.profile.redirects?.find(
                (item) => item.type === "STEPONE"
              )}
              numberCollaborators={data.profile.number_collaborators}
              companyId={data.profile.id}
            />
          )}

          {data.user.type === "COMPANY" && (
            <Authentique companyId={data.profile.id} />
          )}
        </GridCol>
      </GridRow>
    </GridContainer>
  );
};

export default General;
