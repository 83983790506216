import { WhiteContainer } from "components/WhiteContainer";
import { GridContainer } from "layout/components/page-styles";
import React from "react";
import { SelectBase } from "components/Forms/Select/Select";
import { GeneralProps } from "../General/index";
import { Button } from "components/Buttons/styles";
import { updateUserCredentials } from "../../../requests/master";
import { useForm } from "react-hook-form";
import Input from "components/Forms/Input";
import { addToast } from "app/components/Toast";

const Account: React.FC<GeneralProps> = ({ data }) => {
  const [type, setType] = React.useState("email");
  const [emailField, setEmailField] = React.useState("");
  const [passwordField, setPasswordField] = React.useState("senharesetada123");
  const { register, handleSubmit } = useForm();
  const { id, email } = data;

  const handleUpdate = async () => {
    try {
      const userData =
        type === "email" ? { email: emailField } : { password: passwordField };
      await updateUserCredentials(id, userData);
      type === "email"
        ? addToast(`E-mail alterado com sucesso.`, { appearance: "success" })
        : addToast(`Senha alterada com sucesso.`, { appearance: "success" });

      setEmailField("");
      setPasswordField("");
    } catch (error) {
      type === "email"
        ? addToast(`Erro ao alterar o E-mail.`, { appearance: "error" })
        : addToast(`Erro ao alterar a senha.`, { appearance: "error" });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value as string);
  };

  const headingStyle = {
    fontWeight: "normal",
  };

  return (
    <GridContainer>
      <WhiteContainer>
        <h1 style={headingStyle}>Alterações de Conta</h1>
        <h3 style={headingStyle}>Escolha a informação que deseja alterar</h3>
        <SelectBase
          {...register("type")}
          onChange={handleChange}
          style={{
            padding: "14px",
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          <option value={"email"}>E-mail</option>
          <option value={"password"}>Senha</option>
        </SelectBase>
        {type === "email" && (
          <form onSubmit={handleSubmit(handleUpdate)}>
            <p>E-mail Atual:</p>
            <Input
              required={false}
              inputProps={{
                placeholder: email,
                disabled: true,
                style: {
                  padding: "24px 14px",
                  marginBottom: "16px",
                  marginTop: "4px",
                },
              }}
            />
            <Input
              {...register("email")}
              required={false}
              label="Novo E-mail"
              inputProps={{
                value: emailField,
                onChange: (event) => setEmailField(event.target.value),
                style: {
                  padding: "24px 14px",
                  marginBottom: "16px",
                  marginTop: "4px",
                },
              }}
              limit={50}
            />
            <Button
              type="submit"
              disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailField)}
            >
              Alterar
            </Button>
          </form>
        )}
        {type === "password" && (
          <form onSubmit={handleSubmit(handleUpdate)}>
            <Input
              {...register("email")}
              required={false}
              label="Nova Senha"
              inputProps={{
                type: "text",
                value: passwordField,
                onChange: (event) => setPasswordField(event.target.value),
                style: {
                  padding: "24px 14px",
                  marginBottom: "16px",
                  marginTop: "4px",
                },
              }}
              limit={50}
            />
            <Button type="submit" disabled={passwordField.length < 8}>
              Alterar
            </Button>
          </form>
        )}
      </WhiteContainer>
    </GridContainer>
  );
};

export default Account;
