import { links } from "app/config/links";
import { CompanyUpdateInterface } from "interfaces/Companies";
import {
  CompanyRegisterRequest,
  EmailLogRegisters,
  SearchResultProps,
  userDataResponse,
} from "interfaces/Master";
import api from "services/api";
import { userData } from "../interfaces/Master";
export interface IndicatorsProps {
  total_persons: number;
  total_companies: number;
  total: number;
}

export const getIndicators = async () => {
  const response = await api.get(links.api.core + `/user/count/company-person`);
  return response.data as IndicatorsProps;
};

export type SearchUserTypes = "username" | "email" | "search";

export interface SearchUserProps {
  type: SearchUserTypes;
  value: string;
}

export const searchUser = async (data: SearchUserProps) => {
  const response = await api.get(
    links.api.core + `/user/search/email?${data.type}=${data.value}`
  );
  return response.data as SearchResultProps;
};

export const getEmailsLog = async (email: string) => {
  const response = await api.get(
    links.api.core + `/user/email-tracking?email=${email}&page=1&perPage=50`
  );

  return response.data.data as EmailLogRegisters[];
};

export const changeWhiteLabelLogo = async (data: {
  logo: File;
  company_id: string;
  partner_name: string;
}) => {
  const response = await api.post(
    links.api.core + `/companies/change-logo`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return response.data;
};

export const registerCompany = async (data: FormData) => {
  const response = await api.post(links.api.core + `/register/company`, data);
  return response;
};

export const getCompanyCollaborators = async (companyId: string) => {
  const response = await api.get(
    links.api.core +
      "/engineering/collaborators/public?status=LINKED&perPage=99999",
    {
      headers: {
        companyId,
      },
    }
  );

  return response;
};

export const getAllCompanies = async (
  page: number,
  search: string,
  filters: string[]
) => {
  const response = await api.get(links.api.core + `/companies`, {
    params: {
      page,
      search,
      filters,
    },
  });

  return response.data;
};

export const updateCompany = async (
  companyId: string,
  data: CompanyUpdateInterface
) => {
  const response = await api.put(
    links.api.core + `/companies/${companyId}`,
    data
  );
  return response.data;
};

export const updateUserCredentials = async (
  userId: string,
  data: userData
): Promise<userDataResponse> => {
  const response = await api.patch(`/user/update/info/${userId}`, data);
  return response.data;
};
